import HeaderImg from "../assets/imgs/DDAF_Logo_White.png";
import Loader from "../components/Loader";
import emailjs from '@emailjs/browser';
import { useState, useRef } from "react";
const Maintenance = () => {
    const form = useRef();
    const [email, setEmail] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const templateParams = {
            email: email
        }
        emailjs.sendForm('service_c649bof', 'template_ny9t78j', form.current, 'XpkbGApwunUb74qm5')
            .then((result) => {
                console.log(result.text);
                setIsSubmitted(true);
            }, (error) => {
                console.log(error);
            });

    }


    return (
        <>
            <Loader />
            <div className="theme-bg-blue w-full h-screen flex flex-col items-center justify-center space-y-9">
                <img
                    className="header-logo-img-anim w-[300px]"
                    src={HeaderImg}
                    alt="DDAF Logo"
                />
                <section>
                    <div className="container-custom">
                        <div
                            className="flex flex-col justify-center items-center text-center gap-4"
                            data-aos-duration="800"
                            data-aos-delay="600"
                        >
                            <p className="font-Montserrat-Regular font-size-20px text-slate-300 text-white lg:w-[60vw]">
                                Participation in the Decentralised Digital Asset Fund Trust (DDAFT) is by invitation only.
                                <br />
                                If you are interested, please register your interest by providing your email address below:
                            </p>

                            {isSubmitted ? (
                                    <span className="flex rounded-md  bg-[#4f68e7] text-white uppercase px-2 py-1 text-sm font-bold mr-3">
                                        Submitted
                                    </span>
                            ) : (
                                <form ref={form} className="relative w-max mx-auto" onSubmit={handleSubmit}>
                                    <input
                                        className="newsletter-input font-Lato-Regular font-size-20px"
                                        placeholder="Enter Your Email"
                                        value={email}
                                        name='email'
                                        onChange={handleChange}
                                        type="email"
                                        required
                                    />
                                    <button
                                        className="newsletter-btn absolute right-0 top-0 font-Gilroy-Regular"
                                        type="submit"
                                        disabled={!email.trim()} 
                                    >
                                        Submit
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>

    );
};

export default Maintenance;